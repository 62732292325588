.App {
  text-align: center;
  overflow: hidden;
  color: #0a3f2e;
  font-family: Roboto Mono, Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  border: none;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

nav {
  display: flex;
  position: fixed;
  top: 0px;
  width: 100%;
  padding: 12px;
  padding-left: 12vw;
  justify-content: flex-start;
  z-index: 12;
  background-color: #0a3f2e;
  cursor: pointer
}

select {
  width: 100%;
  min-width: 160px;
  flex: 1;
  background-color: #efe7d0;
  border: solid 3px #0a3f2e;
  color: #0a3f2e;
}

.daruma-text {
  font-family: Darumadrop One, Arial, Helvetica, sans-serif;
}

.deep-bg {
  background-color: #efe7d0;
  background-image: url("./images/bg_grid.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  z-index: -100;
}

.parent {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
}

.section {
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.content {
  text-align: left;
  padding: 12vw;
  padding-top: 8vh;
}

.contact {
  padding-bottom: 125px;
  padding-left: 30px;
  color: #efe7d0;
  text-align: center;
}

.accent-button {
  background-color: #0a3f2e;
  color: #efe7d0;
  width: fit-content;
  font-size: x-large;
}

.filter-button {
  background-color: #0a3f2e;
  color: #efe7d0;
  width: fit-content;
  font-size: medium;
  float: right;
  margin-right: 10px;
}

.gm-style .gm-zoom-control .gm-zoom-in,
.gm-style .gm-zoom-control .gm-zoom-out {
  background-color: red;
  color: white;
}

.hero-text {
  height: 100%;
  padding-top: 60px;
  padding-bottom: 5%;
}

.hero-image-container {
  height: 100vh;
  padding: 5%
}

.hero-image {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right top;
  height: 100%
}

.card-container {
  padding: 5%; 
  padding-top: 0px; 
  padding-bottom: 0px;
  aspect-ratio: 1/1;
}

.content-image {
  margin: 0px
}

.date {
  text-align: right;
}

.ingredient {
  height: 3vw;
  margin-right: 0.5vw;
}

.manga-frame {
  height: 400px;
  margin: 0px;
}

.ramen-card {
    max-height: 100%;
    max-width: 100%;
    padding: 0px;
    aspect-ratio: 1/1;
    margin: 0px;
    border: solid 4px #0a3f2e;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: -12px 12px 0px #0a3f2e;
}

.ramen-card::before {
  content: '';
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 63, 46, 0);
  transition: background-color 0.3s;
}

.ramen-card:hover::before {
  background-color: rgba(10, 63, 46, 0.6);
  padding: 0px;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.pad-80right {
  padding-right: 80px;
}

@media (max-width: 992px) {
  nav {
    padding-left: 0px;
  }
  
  .contact {
    padding-bottom: 45vh;
  }

  .contact .coloured-child {
    background-color: #0a3f2e;
  }

  .date {
    text-align: left;
  }

  .filter-button {
    float: none;
    margin-right: 0px;
  }

  .section {
    height: fit-content;
    min-height: 100vh;
    background-size: 150vw auto;
    background-repeat: repeat-y;
  }

  .card-container {
    width: 72%;
    padding: 7%;
  }

  .content-image {
    margin-top: 48px;
  }

  .hero-text {
    padding-left: 10%;
    padding-right: 10%;
  }

  .hero-image-container {
    height: 20vh;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .hero-image {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    margin: 0px
  }

  .ingredient-container {
    padding: 0px;
  }

  .ingredient-row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ingredient {
    height: 7.5vw;
  }

  .manga-frame {
    max-width: 100%;
    height: auto;
    margin-top: 48px;
  }

  .pad-80right {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 12px;
  }
}