.custom-table {
    border-collapse: collapse;
    width: 100%;
  }
  
td {
  border: 4px solid #0a3f2e;
  border-left: none;
  padding: 8px;
  text-align: center;
  background-color: #efe7d0;
}

.custom-table td:not(.wider-cell) {
  width: 11%;
  max-width: 200px;
}

.wider-cell {
  width: 14%;
  max-width: max-content;
  border-right: 4px solid #0a3f2e;
  font-size: 1.35rem;
}

.icon-cell {
  border-bottom: none;
  border-right: none;
  height: 88px;
}

.text-cell {
  border-top: none;
  border-right: none;
  height: 22px;
  padding-top: 0px;
  padding-bottom: 4px;
}

.table-img {
  height: 100%;
}